import styled, { DefaultTheme } from "styled-components";

type TypographyType = Omit<DefaultTheme["typography"], "fontWeight">;

type SanomatType = {
  family: "SanomatWeb";
  variant: keyof TypographyType["SanomatWeb"];
};
type PretendardType = {
  family: "Pretendard";
  variant: keyof TypographyType["Pretendard"];
};
type TypographyProps = SanomatType | PretendardType;

const Typography = styled.p<TypographyProps>(({ theme, family, variant }) => {
  if (family === "SanomatWeb") {
    return {
      ...theme.typography[family][variant],
    };
  }

  return {
    ...theme.typography[family][variant],
  };
});

export default Typography;

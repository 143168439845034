import {
  AboutHeaderWrapper,
  AboutInformation,
  AboutInformationContent,
  AboutInformationTitle,
  AboutInformationWrapper,
  AboutLogoImage,
  AboutMaxSize,
  AboutWrapper,
} from "@/components/About/About.styles";
import And from "@/components/common/And";
import CommonLayout from "@/components/common/CommonLayout";
import MOS from "@/components/common/MOS";
import SubPageTitle from "@/components/common/SubPageTitle";
import Typography from "@/components/common/Typography";
import useMobile from "@/hooks/useMobile";
import DefaultTemplate from "@/templates/DefaultTemplate";

const AboutPage = () => {
  const { mobileStyle } = useMobile();
  return (
    <DefaultTemplate>
      <AboutWrapper>
        <CommonLayout.Container>
          <CommonLayout.Wrapper>
            <AboutMaxSize>
              <AboutHeaderWrapper>
                <SubPageTitle
                  style={{
                    textAlign: "center",
                    marginBottom: 52,
                    ...mobileStyle({ marginBottom: 46 }),
                  }}
                >
                  <MOS variant="fade-left">Building</MOS>{" "}
                  <MOS variant="fade-left" delay={0.1}>
                    is
                  </MOS>
                  <br />
                  <MOS variant="fade-left" delay={0.2}>
                    Our
                  </MOS>{" "}
                  <MOS variant="fade-left" delay={0.3}>
                    DNA
                  </MOS>
                </SubPageTitle>
                <Typography
                  family="Pretendard"
                  variant="body1"
                  style={{
                    ...mobileStyle({ fontSize: 16 }),
                  }}
                >
                  <MOS>
                    100<And>&</And>100 Ventures, headquartered in Seoul and
                    founded in 2018, is a pioneering investment firm.
                  </MOS>
                  <br />
                  <br />
                  <MOS delay={0.1}>
                    We focus on the primary market and incubate early-stage
                    projects, providing funding, guidance, and industry insights
                    to drive the growth of innovative projects in the South
                    Korean blockchain ecosystem.
                  </MOS>
                  <br />
                  <br />
                  <MOS delay={0.2}>
                    Committed to advancing the adoption of blockchain technology
                    and cryptocurrencies, 100<And>&</And>100 Ventures is at the
                    forefront of the industry's transformation.
                  </MOS>
                </Typography>
              </AboutHeaderWrapper>
              <AboutInformationWrapper>
                <AboutInformation>
                  <AboutInformationTitle>
                    <MOS>ADVISE</MOS>
                  </AboutInformationTitle>
                  <AboutInformationContent>
                    <MOS>
                      Zero to One, We walk with partners through everything from
                      product sketches to go-to-market strategies, leveraging
                      our deep understanding of blockchain technology and the
                      ecosystem.
                    </MOS>
                  </AboutInformationContent>
                </AboutInformation>
                <AboutInformation>
                  <AboutInformationTitle>
                    <MOS delay={0.1}>INVEST</MOS>
                  </AboutInformationTitle>
                  <AboutInformationContent>
                    <MOS delay={0.1}>
                      Quality Fund, We invested in 100+ companies with Pride.
                      This is not about money. This is about what we will do
                      future. The future will change depending on how we make
                      the foundation in the beginning.
                    </MOS>
                  </AboutInformationContent>
                </AboutInformation>
                <AboutInformation>
                  <AboutInformationTitle>
                    <MOS delay={0.2} threshold={0.2}>
                      BUILD
                    </MOS>
                  </AboutInformationTitle>
                  <AboutInformationContent>
                    <MOS delay={0.2} threshold={0.2}>
                      Build Together, By strong relationships with our partner
                      companies, We believe successful projects require more
                      than just funding. We learned this simple truth through
                      experience.
                    </MOS>
                  </AboutInformationContent>
                </AboutInformation>
              </AboutInformationWrapper>
            </AboutMaxSize>
            <AboutLogoImage src="/static/images/main-logo-large.png" />
          </CommonLayout.Wrapper>
        </CommonLayout.Container>
      </AboutWrapper>
    </DefaultTemplate>
  );
};

export default AboutPage;

import Button from "@/components/common/Button";
import Typography from "@/components/common/Typography";
import styled, { css } from "styled-components";

export const CardWrapper = styled.li`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 60px;
  ${({ theme }) => css`
    background: ${theme.color.bg[50]};
    box-shadow: 0px 4px 30px rgba(6, 38, 104, 0.15);

    ${theme.breakpoints.media.mobile} {
      flex-direction: column;
      padding: 20px;
    }
  `}
`;

export const CardMainWrapper = styled.div`
  min-width: 227px;
  max-width: 227px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      min-width: unset;
    }
  `}
`;
export const CardImageLogo = styled.img`
  height: 84px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      height: 64px;
    }
  `}
`;

export const CardTitle = styled(Typography).attrs({
  family: "SanomatWeb",
  variant: "body2",
})`
  margin-top: 24px;
  margin-bottom: 12px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 16px;
      margin-top: 16px;
      margin-bottom: 8px;
    }
  `}
`;

export const CardSubTitle = styled(Typography).attrs({
  family: "Pretendard",
  variant: "caption2",
})`
  margin-bottom: 44px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 14px;
      margin-bottom: 20px;
    }
  `}
`;

export const CardButton = styled(Button).attrs({
  size: "medium",
  variant: "fillPoint",
})`
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 12px;
      height: 30px;
      padding: 0 11px;
    }
  `}
`;

export const CardContentWrapper = styled.div`
  max-width: 499px;
  max-height: 264px;
  overflow: hidden;
  transition: all 1s ease;

  @media (pointer: fine) {
    &:hover {
      max-height: 2000px;
    }
  }

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      max-height: 264px;
      transition: all 0.55s ease;

      &.isFullContent {
        max-height: 2000px;
      }
    }
  `}
`;

export const CardContent = styled(Typography).attrs({
  family: "Pretendard",
  variant: "body4",
})`
  white-space: pre-line;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 16px;
    }
  `}
`;

export const MobileCardFullContentIconButton = styled.button`
  display: none;
  & svg {
    width: 24px;
    height: 24px;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      display: block;
      margin: 0 auto;

      &.isFullContent {
        transform: rotate(180deg);
      }
    }
  `}
`;

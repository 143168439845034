import styled from "styled-components";

const Container = styled.div`
  padding: 0 16px;
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const CommonLayout = { Container, Wrapper };

export default CommonLayout;

import ModalPortal from "@/container/ModalPortal";
import {
  NavigationLinkButton,
  NavigationLinkText,
  NavigationWrapper,
} from "./MobileNavigation.styles";
import { NAV_LINK_LIST } from "@/constants/commonHeader";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

type MobileNavigationProps = {
  onClickNavLink: () => void;
  open: boolean;
};
const MobileNavigation = ({ open, onClickNavLink }: MobileNavigationProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);
  return (
    <ModalPortal>
      <NavigationWrapper className={open ? "open" : ""}>
        {NAV_LINK_LIST.map(({ title, path }) => (
          <NavigationLinkButton
            onClick={() => {
              setTimeout(() => {
                navigate(path);
                onClickNavLink();
                if (path.includes("#")) {
                  const id = path.split("#")[1];
                  document.getElementById(id)?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }, 400);
            }}
            className={
              window.location.pathname + window.location.hash === path
                ? "active"
                : ""
            }
          >
            <NavigationLinkText family="SanomatWeb" variant="footerText1">
              {title}
            </NavigationLinkText>
          </NavigationLinkButton>
        ))}
      </NavigationWrapper>
    </ModalPortal>
  );
};

export default MobileNavigation;

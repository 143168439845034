import Typography from "@/components/common/Typography";
import { ellipsisWithLine } from "@/utils/common/styles";
import styled, { css } from "styled-components";

export const CardWrapper = styled.li`
  ${({ theme }) => css`
    @media (pointer: fine) {
      &:hover {
        & .insight-card__image-wrapper img {
          transform: scale(1.2);
        }

        & .insight-card__title {
          color: ${theme.color.point};
        }

        & .insight-card__content {
          color: ${theme.color.point};
        }
      }
    }
  `}
`;

export const CardImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 78.3%;
`;

export const CardImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: all 0.3s ease;
`;

export const CardTitleWrapper = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color.point};

    ${theme.breakpoints.media.mobile} {
      padding: 12px 0;
      margin-bottom: 12px;
    }
  `}
`;
export const CardTitle = styled.p`
  font-family: "SanomatWeb";
  font-size: 30px;
  font-weight: 400;
  ${ellipsisWithLine(2)};

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 16px;
    }
  `}
`;
export const CardContent = styled(Typography).attrs({
  family: "Pretendard",
  variant: "caption2",
})`
  ${ellipsisWithLine(2)};

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 14px;
    }
  `}
`;

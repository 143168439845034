import styled, { css } from "styled-components";

export const NodeWrapper = styled.div`
  padding: 100px 0 123px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      padding: 30px 0 73px;
    }
  `}
`;

export const NodeHeaderWrapper = styled.div`
  margin-bottom: 140px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      margin-bottom: 50px;
    }
  `}
`;

export const NodeCardListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 66px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      gap: 24px;
    }
  `}
`;

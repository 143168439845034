import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import Typography from "../Typography";
import CommonLayout from "../CommonLayout";

export const HeaderContainer = styled(CommonLayout.Container)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;

  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  &.hide {
    transform: translateY(-100%);
  }

  &:not(.isTop) {
    box-shadow: 0 3px 10px rgb(0, 0, 0, 0.1);
  }

  ${({ theme }) => css`
    background: ${theme.color.bg[50]};
  `}
`;
export const HeaderWrapper = styled(CommonLayout.Wrapper).attrs({
  as: "header",
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      height: 59px;
    }
  `}
`;

export const HeaderLogo = styled.img`
  width: 177px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      width: 133px;
    }
  `}
`;

export const HeaderNav = styled.nav`
  display: flex;
  align-items: center;
  gap: 60px;

  ${({ theme }) => css`
    @media screen and (max-width: 860px) {
      gap: 40px;
    }
    ${theme.breakpoints.media.mobile} {
      display: none;
    }
  `}
`;

export const HeaderLink = styled(Link)`
  ${({ theme }) => css`
    &.active {
      color: ${theme.color.point};
    }
  `}
`;

export const HeaderLinkText = styled(Typography).attrs({
  family: "SanomatWeb",
  variant: "headerText2",
})``;

export const MobileHeaderIconButton = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  ${({ theme }) => css`
    display: none;
    ${theme.breakpoints.media.mobile} {
      display: block;
    }
  `}
`;

export const MobileHeaderIconInner = styled.div`
  position: relative;
  width: 16px;
  height: 12px;

  .open & {
    height: 14px;
  }
`;

export const MobileHeaderIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 2px;
  ${({ theme }) => css`
    background: ${theme.color.gray[900]};

    position: absolute;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    &.first {
      top: 0;
      left: 0;
    }
    &.second {
      top: calc(50% - 1px);
      left: 0;
    }
    &.third {
      bottom: 0;
      left: 0;
    }

    .open & {
      transform-origin: 2px;
      width: 21px;
      &.first {
        transform: rotate(45deg);
      }
      &.second {
        opacity: 0;
      }
      &.third {
        transform: rotate(-45deg);
      }
    }
  `}
`;

import React from "react";
import {
  NodeWrapper,
  NodeHeaderWrapper,
  NodeCardListWrapper,
} from "@/components/Node/Node.styles";
import NodeCard from "@/components/Node/NodeCard";
import CommonLayout from "@/components/common/CommonLayout";
import MOS from "@/components/common/MOS";
import SubPageTitle from "@/components/common/SubPageTitle";
import useMobile from "@/hooks/useMobile";
import DefaultTemplate from "@/templates/DefaultTemplate";
import { fetchNode } from "@/firebase/firestore";
import { NodeData } from "@/types";

const NodePage = () => {
  const { mobileStyle } = useMobile();
  const [nodeDataList, setNodeDataList] = React.useState<NodeData[] | []>([]);
  React.useEffect(() => {
    getAllNode();
  }, []);

  const getAllNode = async () => {
    const fetchDataList = await fetchNode();
    setNodeDataList(fetchDataList);
  };

  return (
    <DefaultTemplate>
      <NodeWrapper>
        <CommonLayout.Container>
          <CommonLayout.Wrapper>
            <NodeHeaderWrapper>
              <SubPageTitle
                style={{
                  marginBottom: 60,
                  ...mobileStyle({ marginBottom: 20 }),
                }}
              >
                <MOS variant="fade-left">Running </MOS>{" "}
                <span className="highlight">
                  <MOS variant="fade-left" delay={0.1}>
                    full
                  </MOS>{" "}
                  <MOS variant="fade-left" delay={0.2}>
                    node
                  </MOS>
                  <br />
                  <MOS variant="fade-left" delay={0.3}>
                    and
                  </MOS>{" "}
                  <MOS variant="fade-left" delay={0.4}>
                    validator
                  </MOS>
                  <br />
                </span>
                <span className="light">
                  <MOS variant="fade-left" delay={0.5}>
                    in
                  </MOS>{" "}
                  <MOS variant="fade-left" delay={0.6}>
                    various
                  </MOS>{" "}
                  <MOS variant="fade-left" delay={0.7}>
                    blockchains
                  </MOS>
                </span>
              </SubPageTitle>
            </NodeHeaderWrapper>
            <NodeCardListWrapper>
              {nodeDataList.map((result, index) => {
                return (
                  <MOS key={index} variant="fade-in" threshold={0}>
                    <NodeCard
                      logoSrc={result.nodeLogoUrl}
                      title={result.nodeCompanyName}
                      subTitle={result.nodeCompanyDescription}
                      content={result.nodeExtraDescription}
                      url={result.nodeLink}
                    />
                  </MOS>
                );
              })}
            </NodeCardListWrapper>
          </CommonLayout.Wrapper>
        </CommonLayout.Container>
      </NodeWrapper>
    </DefaultTemplate>
  );
};

export default NodePage;

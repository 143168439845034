import MOS from "@/components/common/MOS";
import {
  CardWrapper,
  CardImageLogo,
  CardTitle,
  CardBadge,
  CardImageLogoWrapper,
} from "./PortfolioCard.styles";

type Props = {
  logoUrl: string;
  invLogoUrl: string;
  logoBackground: string;
  companyName: string;
  category: string;
  linkUrl: string;
};

const PortfolioCard = ({
  logoUrl,
  invLogoUrl,
  logoBackground,
  companyName,
  category,
  linkUrl,
}: Props) => {
  return (
    <MOS variant="fade-in" duration={1} delay={0.2}>
      <a href={linkUrl} target="_blank" rel="noreferrer">
        <CardWrapper background={logoBackground}>
          <CardImageLogoWrapper className="portfolio-card-image-logo-wrapper">
            <CardImageLogo src={logoUrl} />
            <CardImageLogo src={invLogoUrl} />
          </CardImageLogoWrapper>
          <CardTitle>{companyName}</CardTitle>
          <CardBadge>{category}</CardBadge>
        </CardWrapper>
      </a>
    </MOS>
  );
};

export default PortfolioCard;

import styled, { css } from "styled-components";
import Typography from "../common/Typography";
export const AboutWrapper = styled.div`
  padding: 100px 0 120px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      color: ${theme.color.gray[800]};
      padding: 30px 0 73px;
    }
  `}
`;

export const AboutMaxSize = styled.div`
  max-width: 896px;
  margin: 0 auto;
  box-sizing: content-box;
  padding: 0 20px;
`;

export const AboutHeaderWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 100px;
    padding-bottom: 100px;
    border-bottom: 1px solid ${theme.color.gray[300]};
    ${theme.breakpoints.media.mobile} {
      margin-bottom: 60px;
      padding-bottom: 28px;
    }
  `}
`;

export const AboutInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin-bottom: 160px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      gap: 60px;
      margin-bottom: 0;
    }
  `}
`;

export const AboutInformation = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
`;
export const AboutInformationTitle = styled(Typography).attrs({
  family: "SanomatWeb",
  variant: "body1",
})`
  ${({ theme }) => css`
    color: ${theme.color.point};

    ${theme.breakpoints.media.mobile} {
      font-size: 18px;
    }
  `}
`;
export const AboutInformationContent = styled(Typography).attrs({
  family: "Pretendard",
  variant: "body3",
})`
  max-width: 592px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 14px;
    }
  `}
`;

export const AboutLogoImage = styled.img`
  display: block;
  max-width: 993px;
  width: 100%;
  text-align: center;
  margin: 0 auto;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      display: none;
    }
  `}
`;

import styled, { css } from "styled-components";
export const InsightWrapper = styled.div`
  padding: 100px 0 120px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      color: ${theme.color.gray[800]};
      padding: 30px 0 73px;
    }
  `}
`;

export const InsightHeaderWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 120px;
    ${theme.breakpoints.media.mobile} {
      margin-bottom: 50px;
    }
  `}
`;

export const InsightCardWrapper = styled.div`
  display: flex;
  gap: 168px;

  @media screen and (max-width: 1024px) {
    gap: 80px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      flex-direction: column;
      gap: 0;
    }
  `}
`;

export const InsightCardColumn = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 160px;
  width: 100%;

  &:not(:first-child) {
    margin-top: 80px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      gap: 52px;
      &:not(:first-child) {
        margin-top: 52px;
      }
    }
  `}
`;

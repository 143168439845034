import { Link, useLocation } from "react-router-dom";
import {
  HeaderContainer,
  HeaderWrapper,
  HeaderLogo,
  HeaderNav,
  HeaderLinkText,
  HeaderLink,
  MobileHeaderIconButton,
  MobileHeaderIcon,
  MobileHeaderIconInner,
} from "./CommonHeader.styles";
import MobileNavigation from "./MobileNavigation";
import classNames from "classnames";
import { NAV_LINK_LIST } from "@/constants/commonHeader";
import useScrollDirection from "@/hooks/useScrollDirection";
import { useRecoilState } from "recoil";
import { OpenMobileHeaderAtom } from "@/recoil/mobileHeaderState";
import useIsScrollTop from "@/hooks/useIsScrollTop";

const CommonHeader = () => {
  const [openMobileHeader, setOpenMobileHeader] =
    useRecoilState(OpenMobileHeaderAtom);

  const scrollDirection = useScrollDirection();
  const isTop = useIsScrollTop();

  const location = useLocation();

  const visible = scrollDirection === "down" ? "hide" : "show";
  return (
    <>
      <HeaderContainer
        className={classNames(visible, {
          isTop: isTop || visible === "hide",
        })}
      >
        <HeaderWrapper>
          <Link
            to="/"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <HeaderLogo src="/static/images/main-logo.png" />
          </Link>
          <HeaderNav>
            {NAV_LINK_LIST.map(({ title, path }) => {
              const active = location.pathname + location.hash === path;
              return (
                <HeaderLink
                  to={path}
                  key={path}
                  className={classNames({
                    active,
                  })}
                  onClick={() => {
                    if (path.includes("#")) {
                      const id = path.split("#")[1];
                      document.getElementById(id)?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                >
                  <HeaderLinkText>{title}</HeaderLinkText>
                </HeaderLink>
              );
            })}
          </HeaderNav>
          <MobileHamburgerButton
            open={openMobileHeader}
            onClick={() => {
              setOpenMobileHeader((prev) => !prev);
            }}
          />
        </HeaderWrapper>
      </HeaderContainer>
      <MobileNavigation
        open={openMobileHeader}
        onClickNavLink={() => {
          setOpenMobileHeader(false);
        }}
      />
    </>
  );
};

export default CommonHeader;

type MobileHamburgerButtonProps = {
  open: boolean;
  onClick: () => void;
};
const MobileHamburgerButton = ({
  open,
  onClick,
}: MobileHamburgerButtonProps) => {
  return (
    <MobileHeaderIconButton onClick={onClick} className={classNames({ open })}>
      <MobileHeaderIconInner>
        <MobileHeaderIcon className="first" />
        <MobileHeaderIcon className="second" />
        <MobileHeaderIcon className="third" />
      </MobileHeaderIconInner>
    </MobileHeaderIconButton>
  );
};

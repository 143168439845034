import { motion, useAnimation } from "framer-motion";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";

const Cover = styled(motion.div)`
  ${({ theme }) => css`
    background: ${theme.color.point};
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  `}
`;

type useImageCoverProps = {
  threshold?: number;
};
const useImageCover = ({ threshold = 0 }: useImageCoverProps) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold });
  const control = useAnimation();

  useEffect(() => {
    if (inView) {
      control.start("unCover");
    }
  }, [control, inView]);

  type ImageCoverProps = {
    delay?: number;
  };

  const ImageCover = useMemo(
    () =>
      ({ children, delay = 0 }: PropsWithChildren<ImageCoverProps>) => {
        return (
          <Cover
            variants={{
              unCover: {
                transition: {
                  duration: 0.7,
                  delay,
                },
                translateY: "-100%",
              },
              cover: {
                translateY: 0,
              },
            }}
            initial={"cover"}
            animate={control}
            className="image-cover"
          >
            {children}
          </Cover>
        );
      },
    [control]
  );

  return {
    rootRef: ref,
    ImageCover,
  };
};

export default useImageCover;

import {
  InsightCardColumn,
  InsightCardWrapper,
  InsightHeaderWrapper,
  InsightWrapper,
} from "@/components/Insights/Insights.styles";
import InsightCard from "@/components/Insights/InsightCard";
import CommonLayout from "@/components/common/CommonLayout";
import SubPageTitle from "@/components/common/SubPageTitle";
import DefaultTemplate from "@/templates/DefaultTemplate";
import MOS from "@/components/common/MOS";
import React, { useCallback, useEffect, useState } from "react";
import { fetchContents } from "@/firebase/firestore";
import { ContentsData } from "@/types";

const InsightsPage = () => {
  const [contentsDataList, setContentsDataList] = React.useState<
    ContentsData[] | []
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [isNext, setIsNext] = useState<boolean>(true);

  React.useEffect(() => {
    getAllContents();
  }, []);

  const getAllContents = async (page: number = 1) => {
    setIsLoading(true);
    setIsNext(true);
    let fetchDataList = await fetchContents(page);
    const lastData = fetchDataList[fetchDataList.length - 1];
    console.log(fetchDataList)

    setContentsDataList((prev) => [...prev, ...fetchDataList]);
    setIsLoading(false);
    setPage((prev) => prev + 1);
    setIsNext(lastData.isNext);
  };

  const infiniteScroll = useCallback(() => {
    let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    const scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    const clientHeight = document.documentElement.clientHeight;

    scrollHeight -= 600;

    if (scrollTop + clientHeight >= scrollHeight && !isLoading && isNext) {
      getAllContents(page);
    }
  }, [page, isLoading, isNext]);

  useEffect(() => {
    window.addEventListener('scroll', infiniteScroll, true);
    return () => window.removeEventListener('scroll', infiniteScroll, true);
  }, [infiniteScroll]);

  return (
    <DefaultTemplate>
      <InsightWrapper>
        <CommonLayout.Container>
          <CommonLayout.Wrapper>
            <InsightHeaderWrapper>
              <SubPageTitle>
                <span className="highlight">
                  <MOS variant="fade-left">Insights</MOS>{" "}
                </span>
                <MOS variant="fade-left" delay={0.1}>
                  on
                </MOS>{" "}
                <br />
                <MOS variant="fade-left" delay={0.2}>
                  growth
                </MOS>{" "}
                <MOS variant="fade-left" delay={0.3}>
                  and
                </MOS>{" "}
                <MOS variant="fade-left" delay={0.4}>
                  scaling
                </MOS>
              </SubPageTitle>
            </InsightHeaderWrapper>
            <InsightCardWrapper>
              <InsightCardColumn>
                {contentsDataList.map((result, index) => {
                  if ((index + 1) % 2 !== 0) {
                    return (
                      <InsightCard
                        key={index}
                        imageUrl={result.contentsImageUrl}
                        name={result.contentsName}
                        description={result.contentsDescription}
                        linkUrl={result.contentsLink}
                      />
                    );
                  }
                  return <></>;
                })}
              </InsightCardColumn>
              <InsightCardColumn>
                {contentsDataList.map((result, index) => {
                  if ((index + 1) % 2 === 0) {
                    return (
                      <InsightCard
                        key={index}
                        imageUrl={result.contentsImageUrl}
                        name={result.contentsName}
                        description={result.contentsDescription}
                        linkUrl={result.contentsLink}
                      />
                    );
                  }
                  return <></>;
                })}
              </InsightCardColumn>
            </InsightCardWrapper>
          </CommonLayout.Wrapper>
        </CommonLayout.Container>
      </InsightWrapper>
    </DefaultTemplate>
  );
};

export default InsightsPage;

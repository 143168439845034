import styled, { css } from "styled-components";
import Typography from "../Typography";

const SubPageTitle = styled(Typography).attrs({
  family: "SanomatWeb",
  variant: "h1",
})`
  ${({ theme }) => css`
    color: ${theme.color.gray[800]};
    & .highlight {
      color: ${theme.color.point};
    }

    & .light {
      font-weight: 400;
    }

    ${theme.breakpoints.media.mobile} {
      font-size: 27px;
    }
  `}
`;

export default SubPageTitle;

import { CardImage, CardImageWrapper } from "./MainPortfolioCard.styles";

type MainPortfolioCardProps = {
  src: string;
  oppositeSrc: string;
  background: string;
  linkUrl: string;
};
const MainPortfolioCard = ({
  src,
  oppositeSrc,
  background,
  linkUrl,
}: MainPortfolioCardProps) => {
  return (
    <a href={linkUrl} target="_blank" rel="noreferrer">
      <CardImageWrapper background={background}>
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <CardImage src={src} />
          <CardImage src={oppositeSrc} />
        </div>
      </CardImageWrapper>
    </a>
  );
};

export default MainPortfolioCard;

import styled, { css } from "styled-components";

export const PortfolioWrapper = styled.div`
  padding: 120px 0 100px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      padding: 30px 0 73px;
    }
  `}
`;

export const PortfolioHeadWrapper = styled.div`
  margin-bottom: 120px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      margin-bottom: 30px;
    }
  `}
`;

export const PortfolioCardListWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 60px;
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  `}
`;

import useMobile from "@/hooks/useMobile";
import { Variants, motion, useAnimation } from "framer-motion";
import { PropsWithChildren, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const Span = styled(motion.span)`
  display: inline-block;
`;

type MOSOptions = {
  delay: number;
  offset: number;
  duration: number;
  easings: string;
};

const MOSDirection = {
  "fade-up": ({ duration, delay, offset, easings }: MOSOptions): Variants => ({
    visible: {
      opacity: 1,
      transition: { duration, delay, easings },
      translateY: 0,
    },
    hidden: { opacity: 0, translateY: offset },
  }),
  "fade-down": ({
    duration,
    delay,
    offset,
    easings,
  }: MOSOptions): Variants => ({
    visible: {
      opacity: 1,
      transition: { duration, delay, easings },
      translateY: 0,
    },
    hidden: { opacity: 0, translateY: offset * -1 },
  }),
  "fade-left": ({
    duration,
    delay,
    offset,
    easings,
  }: MOSOptions): Variants => ({
    visible: {
      opacity: 1,
      transition: { duration, delay, easings },
      translateX: 0,
    },
    hidden: { opacity: 0, translateX: offset },
  }),
  "fade-right": ({ duration, delay, offset, easings }: MOSOptions) => ({
    visible: {
      opacity: 1,
      transition: { duration, delay, easings },
      translateX: 0,
    },
    hidden: { opacity: 0, translateX: offset * -1 },
  }),
  "fade-in": ({ duration, delay, easings }: Omit<MOSOptions, "offset">) => ({
    visible: {
      opacity: 1,
      transition: { duration, delay, easings },
    },
    hidden: { opacity: 0 },
  }),
};

type MOSProps = {
  variant?: keyof typeof MOSDirection;
  style?: React.CSSProperties;
  once?: boolean;
  threshold?: number;
} & Partial<MOSOptions>;

const initialDesktop = {
  delay: 0,
  offset: 100,
  duration: 0.7,
  easings: "ease",
};
const initialMobile = {
  delay: 0,
  offset: 40,
  duration: 0.7,
  easings: "ease",
};

const MOS = ({
  children,
  variant = "fade-up",
  style,
  once = true,
  threshold = 0.5,
  ...options
}: PropsWithChildren<MOSProps>) => {
  const control = useAnimation();
  const { isMobile } = useMobile();
  const { ref, inView } = useInView({ triggerOnce: once, threshold });

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const defaultOptions = isMobile ? initialMobile : initialDesktop;
  return (
    <Span
      ref={ref}
      variants={MOSDirection[variant]({
        ...defaultOptions,
        ...options,
      })}
      initial={"hidden"}
      animate={control}
      style={style}
    >
      {children}
    </Span>
  );
};

export default MOS;

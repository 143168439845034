import CommonFooter from "@/components/common/CommonFooter";
import CommonHeader from "@/components/common/CommonHeader";
import { PropsWithChildren } from "react";

type DefaultTemplateProps = { bgWhite?: boolean };
const DefaultTemplate = ({
  children,
  bgWhite,
}: PropsWithChildren<DefaultTemplateProps>) => {
  return (
    <>
      <CommonHeader />
      <main>{children}</main>
      <CommonFooter bgWhite={bgWhite} />
    </>
  );
};

export default DefaultTemplate;

import classNames from "classnames";
import styled, { css } from "styled-components";

type ButtonProps = {
  size: "small" | "medium" | "large";
  variant: "fillPoint" | "fillWhite";
};

const Button = styled.button<ButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${({ theme, size }) => {
    switch (size) {
      case "small":
        return css`
          height: 28px;
          padding: 0 16px;
          font-family: ${theme.typography.Pretendard.button.fontFamily};
          font-size: ${theme.typography.Pretendard.button.fontSize};
          font-weight: ${theme.typography.Pretendard.button.fontWeight};
        `;
      case "medium":
        return css`
          height: 46px;
          padding: 0 24px;
          font-family: ${theme.typography.SanomatWeb.button1.fontFamily};
          font-size: ${theme.typography.SanomatWeb.button1.fontSize};
          font-weight: ${theme.typography.SanomatWeb.button1.fontWeight};
        `;
      default:
        return css`
          height: 53px;
          padding: 0 60px;
          font-family: ${theme.typography.SanomatWeb.button1.fontFamily};
          font-size: ${theme.typography.SanomatWeb.button1.fontSize};
          font-weight: ${theme.typography.SanomatWeb.button1.fontWeight};
        `;
    }
  }}
  ${({ theme, variant }) => {
    switch (variant) {
      case "fillPoint":
        return css`
          background: ${theme.color.point};
          color: ${theme.color.bg[50]};
        `;
      default:
        return css`
          background: ${theme.color.bg[50]};
          color: ${theme.color.point};
        `;
    }
  }}
`;

export default Button;

import {
  CardContent,
  CardImage,
  CardImageWrapper,
  CardTitle,
  CardTitleWrapper,
  CardWrapper,
} from "./InsightCard.styles";

import useImageCover from "@/hooks/useImageCover";
import MOS from "@/components/common/MOS";

type Props = {
  imageUrl?: string;
  name?: string;
  description?: string;
  linkUrl?: string;
};

const InsightCard = ({ imageUrl, name, description, linkUrl }: Props) => {
  const threshold = 0.3;
  const { rootRef, ImageCover } = useImageCover({ threshold });
  return (
    <CardWrapper ref={rootRef}>
      <MOS variant="fade-in" duration={0.4} threshold={threshold}>
        <CardImageWrapper className="insight-card__image-wrapper">
          <a href={linkUrl} target="_blank" rel="noreferrer">
            <CardImage src={imageUrl} />
            <ImageCover delay={0.1} />
          </a>
        </CardImageWrapper>
        <CardTitleWrapper>
          <CardTitle className="insight-card__title">{name}</CardTitle>
        </CardTitleWrapper>
        <CardContent className="insight-card__content">
          {description}
        </CardContent>
      </MOS>
    </CardWrapper>
  );
};

export default InsightCard;

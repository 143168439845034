import { createGlobalStyle } from "styled-components";

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: "SanomatWeb";
    font-weight: 700;
    src: url("/static/fonts/SanomatWeb/Sanomat-Web-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "SanomatWeb";
    font-weight: 400;
    src: url("/static/fonts/SanomatWeb/Sanomat-Web-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 900;
    font-display: swap;
    src: local('Pretendard Black'),
      url('/static/fonts/Pretendard/woff2/Pretendard-Black.woff2') format('woff2'),
      url('/static/fonts/Pretendard/woff/Pretendard-Black.woff') format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 800;
    font-display: swap;
    src: local('Pretendard ExtraBold'),
      url('/static/fonts/Pretendard/woff2/Pretendard-ExtraBold.woff2') format('woff2'),
      url('/static/fonts/Pretendard/woff/Pretendard-ExtraBold.woff') format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    font-display: swap;
    src: local('Pretendard Bold'),
      url('/static/fonts/Pretendard/woff2/Pretendard-Bold.woff2') format('woff2'),
      url('/static/fonts/Pretendard/woff/Pretendard-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 600;
    font-display: swap;
    src: local('Pretendard SemiBold'),
      url('/static/fonts/Pretendard/woff2/Pretendard-SemiBold.woff2') format('woff2'),
      url('/static/fonts/Pretendard/woff/Pretendard-SemiBold.woff') format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    font-display: swap;
    src: local('Pretendard Medium'),
      url('/static/fonts/Pretendard/woff2/Pretendard-Medium.woff2') format('woff2'),
      url('/static/fonts/Pretendard/woff/Pretendard-Medium.woff') format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    font-display: swap;
    src: local('Pretendard Regular'),
      url('/static/fonts/Pretendard/woff2/Pretendard-Regular.woff2') format('woff2'),
      url('/static/fonts/Pretendard/woff/Pretendard-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 300;
    font-display: swap;
    src: local('Pretendard Light'),
      url('/static/fonts/Pretendard/woff2/Pretendard-Light.woff2') format('woff2'),
      url('/static/fonts/Pretendard/woff/Pretendard-Light.woff') format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 200;
    font-display: swap;
    src: local('Pretendard ExtraLight'),
      url('/static/fonts/Pretendard/woff2/Pretendard-ExtraLight.woff2') format('woff2'),
      url('/static/fonts/Pretendard/woff/Pretendard-ExtraLight.woff') format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 100;
    font-display: swap;
    src: local('Pretendard Thin'),
      url('/static/fonts/Pretendard/woff2/Pretendard-Thin.woff2') format('woff2'),
      url('/static/fonts/Pretendard/woff/Pretendard-Thin.woff') format('woff');
  }

  @font-face {
    font-family: 'NimbusRomNo';
    font-weight: 400;
    src: url("/static/fonts/NimbusRomNo9L/NimbusRomNo9L-Reg.otf") format("truetype");
  }

`;

export default FontStyles;

import Typography from "@/components/common/Typography";
import styled, { css } from "styled-components";

const cardTransition = "all 0.35s ease";
export const CardWrapper = styled.li<{ background: string }>`
  padding: 16px 28px;
  position: relative;

  ${({ theme, background }) => css`
    & .portfolio-card-image-logo-wrapper img:last-child {
      visibility: hidden;
      opacity: 0;
    }

    @media (pointer: fine) {
      &::before {
        transition: ${cardTransition};
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        opacity: 0;
        z-index: -1;
        background: ${background};
      }

      &:hover::before {
        width: 100%;
        height: 100%;
        opacity: 1;
      }

      & .portfolio-card-image-logo-wrapper img:last-child {
        visibility: hidden;
        opacity: 0;
      }
      &:hover {
        & .portfolio-card-image-logo-wrapper img:first-child {
          visibility: hidden;
          opacity: 0;
        }
        & .portfolio-card-image-logo-wrapper img:last-child {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    ${theme.breakpoints.media.mobile} {
      padding: 16px;
    }
  `}
`;

export const CardImageLogoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 92px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      height: 52px;
    }
  `}
`;
export const CardImageLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left top;
  position: absolute;
  top: 0;
  left: 0;
  transition: ${cardTransition};
`;

export const CardTitle = styled(Typography).attrs({
  family: "SanomatWeb",
  variant: "body2",
})`
  ${({ theme }) => css`
    margin-top: 10px;
    margin-bottom: 12px;
    color: ${theme.color.gray[800]};
    transition: ${cardTransition};

    @media (pointer: fine) {
      ${CardWrapper}:hover & {
        color: ${theme.color.bg[50]};
      }
    }

    ${theme.breakpoints.media.mobile} {
      margin-top: 8px;
      margin-bottom: 8px;

      font-size: 14px;
    }
  `}
`;

export const CardBadge = styled.span`
  ${({ theme }) => css`
    transition: ${cardTransition};
    padding: 2px 10px;
    font-size: 10px;
    font-weight: 600;
    color: ${theme.color.bg[50]};
    background: ${theme.color.point};

    @media (pointer: fine) {
      ${CardWrapper}:hover & {
        background: ${theme.color.bg[50]};
        color: ${theme.color.point};
      }
    }

    ${theme.breakpoints.media.mobile} {
      height: 22px;
      font-size: 12px;
      padding: 0 12px;
    }
  `}
`;

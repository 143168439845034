import styled, { css } from "styled-components";

export const CardImageWrapper = styled.li<{ background: string }>`
  position: relative;
  height: 141px;
  width: 100%;
  padding: 10px;
  ${({ theme, background }) => css`
    & img:last-child {
      visibility: hidden;
      opacity: 0;
    }

    .blue-color & {
      & img:first-child {
        visibility: hidden;
        opacity: 0;
      }

      & img:last-child {
        visibility: visible;
        opacity: 1;
      }
    }
    @media (pointer: fine) {
      &::before {
        transition: all 0.35s ease;
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        opacity: 0;
        background: ${background};

        .blue-color & {
          background: ${theme.color.bg[50]};
        }
      }

      & img:last-child {
        visibility: hidden;
        opacity: 0;
      }
      &:hover {
        & img:first-child {
          visibility: hidden;
          opacity: 0;
        }
        & img:last-child {
          visibility: visible;
          opacity: 1;
        }
      }

      .blue-color & {
        & img:first-child {
          visibility: hidden;
          opacity: 0;
        }

        & img:last-child {
          visibility: visible;
          opacity: 1;
        }
        &:hover {
          & img:last-child {
            visibility: hidden;
            opacity: 0;
          }
          & img:first-child {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      &:hover::before {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }

    ${theme.breakpoints.media.mobile} {
      padding: 16px;
      height: 84px;
    }
  `}
`;
export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.35s ease;
`;

import styled, { css } from "styled-components";
import Typography from "../../Typography";

export const NavigationWrapper = styled.div`
  position: fixed;
  top: 59px;
  left: 0;
  height: 100vh;
  width: 100%;
  padding: 40px 0;
  z-index: 9999;
  ${({ theme }) => css`
    background-color: ${theme.color.bg[50]};
    display: none;
    ${theme.breakpoints.media.mobile} {
      display: block;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease;
      &.open {
        visibility: visible;
        opacity: 1;
      }
    }
  `}
`;

export const NavigationLinkButton = styled.button`
  width: 100%;
  text-align: center;
  padding: 28px;
  ${({ theme }) => css`
    &.active:active & {
      background: ${theme.color.bg[100]};
    }
  `}
`;

export const NavigationLinkText = styled(Typography)`
  position: relative;
  display: inline-block;
  ${({ theme }) => css`
    &::after {
      content: "";
      display: inline-flex;
      position: absolute;
      left: 50%;
      bottom: 4px;
      transform: translateX(-50%);
      background: ${theme.color.point};
      height: 2px;
      width: 0;
      transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }
    .active & {
      color: ${theme.color.point};

      &::after {
        width: 100%;
      }
    }
  `};
`;

import { NAV_LINK_LIST } from "@/constants/commonHeader";
import Typography from "../Typography";
import {
  FooterContainer,
  FooterContentWrapper,
  FooterSiteMapLinkText,
  FooterSiteMapWrapper,
  FooterWrappper,
} from "./CommonFooter.styles";
import { NavLink } from "react-router-dom";
import useMobile from "@/hooks/useMobile";
import classNames from "classnames";
import And from "../And";

type CommonFooterProps = {
  bgWhite?: boolean;
};

const CommonFooter = ({ bgWhite }: CommonFooterProps) => {
  const { mobileStyle } = useMobile();
  return (
    <FooterContainer className={classNames({ bgWhite })}>
      <FooterWrappper>
        <FooterContentWrapper>
          <div style={{ maxWidth: 713 }}>
            <Typography
              family="SanomatWeb"
              variant="footerText2"
              style={{
                marginBottom: 17,
                letterSpacing: "0.02em",
                ...mobileStyle({
                  fontSize: 16,
                }),
              }}
            >
              © 2018 &nbsp; 100<And>&</And>100 Ventures Corp. All
              Reserved.
            </Typography>
            <Typography
              family="Pretendard"
              variant="caption2"
              style={{ fontWeight: 300 }}
            >
              The portfolio companies included herein do not represent all of
              the portfolio companies purchased, sold or recommended for funds
              advised by 100<And>&</And>100 Ventures, Corp. The reader
              should not assume that an investment in the portfolio companies
              identified was or will be profitable.
            </Typography>
          </div>
          <div style={{ maxWidth: 224 }}>
            <Typography
              family="SanomatWeb"
              variant="footerText1"
              style={{
                marginBottom: 8,
                ...mobileStyle({
                  fontSize: 16,
                }),
              }}
            >
              Contact
            </Typography>
            <Typography
              family="Pretendard"
              variant="caption2"
              style={{
                fontWeight: 300,
                ...mobileStyle({
                  fontSize: 14,
                }),
              }}
            >
              contact@100100.vc
            </Typography>
          </div>
        </FooterContentWrapper>
        <FooterSiteMapWrapper>
          {NAV_LINK_LIST.map(({ title, path }) => (
            <NavLink to={path} key={path}>
              <FooterSiteMapLinkText
                style={{
                  ...mobileStyle({
                    fontSize: 16,
                  }),
                }}
              >
                {title}
              </FooterSiteMapLinkText>
            </NavLink>
          ))}
        </FooterSiteMapWrapper>
      </FooterWrappper>
    </FooterContainer>
  );
};

export default CommonFooter;

import MOS from "@/components/common/MOS";
import {
  CardImage,
  CardImageWrapper,
  CardText,
  CardWrapper,
} from "./MainNewsInsightsCard.styles";
import useImageCover from "@/hooks/useImageCover";

type Props = {
  imageUrl?: string;
  name?: string;
  linkUrl?: string;
};

const MainNewsInsightsCard = ({ imageUrl, name, linkUrl }: Props) => {
  const threshold = 0.3;
  const { rootRef, ImageCover } = useImageCover({ threshold });
  return (
    <MOS variant="fade-in" duration={0.4} threshold={threshold}>
      <a href={linkUrl} target="_blank" rel="noreferrer">
        <CardWrapper ref={rootRef}>
          <CardImageWrapper>
            <CardImage src={imageUrl} />
            <ImageCover />
          </CardImageWrapper>
          <CardText>{name}</CardText>
        </CardWrapper>
      </a>
    </MOS>
  );
};

export default MainNewsInsightsCard;

import { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";

type useTypingTextProps = {
  words: string[];
  keySpeed?: number;
  backspaceKeySpeed?: number;
  maxPauseAmount?: number;
  emptyStart?: boolean;
};

const FORWARD = "forward";
const BACKWARD = "backward";

const useTypingText = ({
  words,
  keySpeed = 1000,
  backspaceKeySpeed = 1000,
  maxPauseAmount = 10,
  emptyStart = true,
}: useTypingTextProps) => {
  const [wordIndex, setWordIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState<string[]>(
    emptyStart ? [] : words[wordIndex].split("")
  );
  const [isStopped, setIsStopped] = useState(false);
  const direction = useRef(emptyStart ? FORWARD : BACKWARD);
  const typingInterval = useRef<ReturnType<typeof setTimeout>>();
  const letterIndex = useRef<number>();

  const stop = () => {
    clearInterval(typingInterval.current);
    setIsStopped(true);
  };

  useEffect(() => {
    // Start at 0
    let pauseCounter = 0;

    const customKeySpeed =
      direction.current !== BACKWARD ? keySpeed : backspaceKeySpeed;

    if (isStopped) return;

    const typeLetter = () => {
      const letterIdx = letterIndex.current || 0;
      if (letterIdx >= words[wordIndex].length) {
        direction.current = BACKWARD;

        // Begin pause by setting the maxPauseAmount prop equal to the counter
        pauseCounter = maxPauseAmount;
        return;
      }

      const segment = words[wordIndex].split("");
      setCurrentWord(currentWord.concat(segment[letterIdx]));
      letterIndex.current = letterIdx + 1;
    };

    const backspace = () => {
      if (letterIndex.current === 0) {
        const isOnLastWord = wordIndex === words.length - 1;

        setWordIndex(!isOnLastWord ? wordIndex + 1 : 0);
        direction.current = FORWARD;

        return;
      }

      const segment = currentWord.slice(0, currentWord.length - 1);
      setCurrentWord(segment);
      letterIndex.current = currentWord.length - 1;
    };

    typingInterval.current = setInterval(() => {
      if (pauseCounter > 0) {
        pauseCounter = pauseCounter - 1;
        return;
      }

      if (direction.current === FORWARD) {
        typeLetter();
      } else {
        backspace();
      }
    }, customKeySpeed);

    return () => {
      clearInterval(typingInterval.current);
    };
  }, [currentWord, wordIndex, keySpeed, words, maxPauseAmount, isStopped]);

  return {
    word: (
      <Word className={`word ${currentWord.length ? "full" : "empty"}`}>
        <span>{currentWord.length ? currentWord.join("") : "0"}</span>
      </Word>
    ),
    start: () => setIsStopped(false),
    stop,
  };
};

const blinkKeyframe = keyframes`
  from {
    opacity: 100%;
  }

  to {
    opacity: 0%;
  }
`;

const Word = styled.span`
  ${({ theme }) => css`
    & span {
      position: relative;

      &:after {
        content: "";
        width: 8px;
        height: 100%;
        background: ${theme.color.point};
        display: block;
        position: absolute;
        right: -10px;
        top: -4px;
        animation: ${blinkKeyframe} 0.3s ease infinite alternate-reverse;
      }
    }
    &.empty {
      visibility: hidden;

      & span:after {
        visibility: visible;
        right: 0;
      }
    }
  `}
`;

export default useTypingText;

const fontWeight = {
  SanomatWeb: {
    bold: 700,
    regular: 400,
  },
  Pretendard: {
    bold: 700,
    semiBold: 600,
    medium: 500,
    regular: 400,
  },
};

const breakpoints = {
  mobile: 768,
};

const GlobalTheme = {
  color: {
    gray: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#CCCCCC",
      500: "#AAAAAA",
      600: "#616161",
      700: "#424242",
      800: "#222222",
      900: "#111111",
    },
    point: "#062668",
    red: {
      400: "#FF5555",
    },
    bg: {
      50: "#FFFFFF",
      100: "#F7F8FA",
    },
  },
  portfolio: {
    bg: "",
  },
  breakpoints: {
    points: breakpoints,
    media: {
      mobile: `@media screen and (max-width:${breakpoints.mobile}px)`,
    },
  },
  typography: {
    fontWeight,
    SanomatWeb: {
      h1: {
        fontFamily: "SanomatWeb",
        fontSize: "100px",
        fontWeight: fontWeight.SanomatWeb.bold,
        lineHeight: "1.2",
        letterSpacing: "-2%",
      },
      h2: {
        fontFamily: "SanomatWeb",
        fontSize: "80px",
        fontWeight: fontWeight.SanomatWeb.bold,
        lineHeight: "1",
        letterSpacing: "-2%",
      },
      subtitle1: {
        fontFamily: "SanomatWeb",
        fontSize: "78px",
        fontWeight: fontWeight.SanomatWeb.bold,
        lineHeight: "1.2",
        letterSpacing: "-1%",
      },
      body1: {
        fontFamily: "SanomatWeb",
        fontSize: "36px",
        fontWeight: fontWeight.SanomatWeb.bold,
        lineHeight: "1.4",
        letterSpacing: "0",
      },
      body2: {
        fontFamily: "SanomatWeb",
        fontSize: "21px",
        fontWeight: fontWeight.SanomatWeb.bold,
        lineHeight: "1.4",
        letterSpacing: "0",
      },
      footerText1: {
        fontFamily: "SanomatWeb",
        fontSize: "24px",
        fontWeight: fontWeight.SanomatWeb.regular,
        lineHeight: "1.4",
        letterSpacing: "-2%",
      },
      footerText2: {
        fontFamily: "SanomatWeb",
        fontSize: "18px",
        fontWeight: fontWeight.SanomatWeb.regular,
        lineHeight: "1.4",
        letterSpacing: "-2%",
      },
      headerText1: {
        fontFamily: "SanomatWeb",
        fontSize: "21px",
        fontWeight: fontWeight.SanomatWeb.bold,
        lineHeight: "1.4",
        letterSpacing: "0",
      },
      headerText2: {
        fontFamily: "SanomatWeb",
        fontSize: "21px",
        fontWeight: fontWeight.SanomatWeb.regular,
        lineHeight: "1.4",
        letterSpacing: "0",
      },
      button1: {
        fontFamily: "SanomatWeb",
        fontSize: "18px",
        fontWeight: fontWeight.SanomatWeb.bold,
        lineHeight: "1.4",
        letterSpacing: "0",
      },
    },
    Pretendard: {
      subtitle1: {
        fontFamily: "Pretendard",
        fontSize: "30px",
        fontWeight: fontWeight.Pretendard.semiBold,
        lineHeight: "1.4",
      },
      subtitle2: {
        fontFamily: "Pretendard",
        fontSize: "27px",
        fontWeight: fontWeight.Pretendard.bold,
        lineHeight: "1.4",
      },
      body1: {
        fontFamily: "Pretendard",
        fontSize: "27px",
        fontWeight: fontWeight.Pretendard.regular,
        lineHeight: "1.4",
      },
      body2: {
        fontFamily: "Pretendard",
        fontSize: "24px",
        fontWeight: fontWeight.Pretendard.medium,
        lineHeight: "1.4",
      },
      body3: {
        fontFamily: "Pretendard",
        fontSize: "24px",
        fontWeight: fontWeight.Pretendard.regular,
        lineHeight: "1.4",
      },
      body4: {
        fontFamily: "Pretendard",
        fontSize: "21px",
        fontWeight: fontWeight.Pretendard.regular,
        lineHeight: "1.4",
      },
      caption1: {
        fontFamily: "Pretendard",
        fontSize: "18px",
        fontWeight: fontWeight.Pretendard.regular,
        lineHeight: "1.4",
      },
      caption2: {
        fontFamily: "Pretendard",
        fontSize: "16px",
        fontWeight: fontWeight.Pretendard.regular,
        lineHeight: "1.4",
      },
      button: {
        fontFamily: "Pretendard",
        fontSize: "14px",
        fontWeight: fontWeight.Pretendard.semiBold,
        lineHeight: "1.4",
      },
    },
  },
};

export default GlobalTheme;

import styled, { css } from "styled-components";
import Typography from "../Typography";
import CommonLayout from "../CommonLayout";

export const FooterContainer = styled(CommonLayout.Container)`
  ${({ theme }) => css`
    background-color: ${theme.color.point};
    color: ${theme.color.bg[50]};

    &.bgWhite {
      background-color: ${theme.color.bg[50]};
      color: ${theme.color.point};
    }
  `}
`;
export const FooterWrappper = styled(CommonLayout.Wrapper).attrs({
  as: "footer",
})`
  padding: 49px 0;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      padding: 40px 0;
    }
  `}
`;

export const FooterContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      flex-direction: column;
    }
  `}
`;

export const FooterSiteMapWrapper = styled.div`
  margin-top: 52px;
  display: flex;
  gap: 40px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      margin-top: 32px;
      gap: 20px;
    }
  `}
`;

export const FooterSiteMapLinkText = styled(Typography).attrs({
  family: "SanomatWeb",
  variant: "footerText2",
})``;

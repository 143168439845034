import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  limit,
} from "firebase/firestore";
import { db } from "@/firebase";
import { ContentsData, NodeData, PortfolioData } from "@/types";

export const fetchNode = async (): Promise<NodeData[]> => {
  const q = query(
    collection(db, "node"),
    where("isEnabledNode", "==", true),
    orderBy("nodeNo", "asc")
  );
  try {
    const querySnapshot = await getDocs(q);
    let result: NodeData[] = [];

    querySnapshot.forEach((doc) => {
      const nodeData: NodeData = doc.data() as NodeData;
      result.push(nodeData);
    });
    return result;
  } catch (error) {
    console.error("Error services/firebase/firestore/fetchNode : ", error);
    throw error;
  }
};

export const fetchContents = async (page: number = 1): Promise<ContentsData[]> => {
  const LIMIT = 4;
  let q: any;

  if (page === 1) {
    q = query(
      collection(db, "contents"),
      where("isEnabledContents", "==", true),
      limit(LIMIT),
      orderBy("contentsNo", "asc")
    );
  } else {
    q = query(
      collection(db, "contents"),
      where("isEnabledContents", "==", true),
      where("contentsNo", ">", ((page - 1) * LIMIT) + 1),
      limit(LIMIT),
      orderBy("contentsNo", "asc")
    );
  }

  try {
    const querySnapshot = await getDocs(q);
    const length = querySnapshot.size;
    let result: ContentsData[] = [];
    let index = 1;

    querySnapshot.forEach((doc) => {
      let contentsData: ContentsData = doc.data() as ContentsData;
      let obj: any = { ...contentsData, isNext: false }

      if (index === length) {
        obj.isNext = index < LIMIT ? false : true;
        result.push(obj)
        return;
      }

      result.push(contentsData);
      index += 1;
    });

    return result;
  } catch (error) {
    console.error("Error services/firebase/firestore/fetchContents : ", error);
    throw error;
  }
};

export const fetchPortfolio = async (): Promise<PortfolioData[]> => {
  const q = query(
    collection(db, "portfolio"),
    where("isEnabledPortfolio", "==", true),
    orderBy("portfolioNo", "asc")
  );
  try {
    const querySnapshot = await getDocs(q);
    let result: PortfolioData[] = [];

    querySnapshot.forEach((doc) => {
      const portfolioData: PortfolioData = doc.data() as PortfolioData;
      result.push(portfolioData);
    });
    return result;
  } catch (error) {
    console.error("Error services/firebase/firestore/fetchPortfolio : ", error);
    throw error;
  }
};

export const fetchMainPage = async (): Promise<PortfolioData[]> => {
  const q = query(
    collection(db, "portfolio"),
    where("isEnabledMainPage", "==", true),
    limit(8),
    orderBy("mainPageNo", "asc")
  );
  try {
    const querySnapshot = await getDocs(q);
    let result: PortfolioData[] = [];

    querySnapshot.forEach((doc) => {
      const portfolioData: PortfolioData = doc.data() as PortfolioData;
      result.push(portfolioData);
    });
    return result;
  } catch (error) {
    console.error("Error services/firebase/firestore/fetchPortfolio : ", error);
    throw error;
  }
};

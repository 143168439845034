import { useEffect, useRef, useState } from "react";
import MainNewsInsightsCard from "@/components/Main/MainNewsInsightsCard";
import {
  MainHeroBackground,
  MainHeroSubTitle,
  MainHeroTitle,
  MainHeroSection,
  MainSection2,
  MainInfoTitle,
  MainInfoWrapper,
  MainInfoText,
  MainSection3,
  MainSectionTitle,
  MainSectionSubTitle,
  MainSectionText,
  MainSectionButton,
  MainLineMovingWrapper,
  MainSection4,
  MainInvestmentInfoWrapper,
  MainSection5,
  MainNewsInsightsWrapper,
  MainSection6,
  MainTeamName,
  MainTeamRow,
  MainTeamItem,
  MainTeamPart,
  MainTeamInfoList,
  MainTeamInfoItem,
  MainTeamSubInfo,
  MainTeamWrapper,
  MainLineMovingMarquee,
  MainLineMovingText,
} from "@/components/Main/index.styles";
import CommonLayout from "@/components/common/CommonLayout";
import useMobile from "@/hooks/useMobile";
import useTypingText from "@/hooks/useTypingText";
import DefaultTemplate from "@/templates/DefaultTemplate";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import useElementInView from "@/hooks/useElementInView";
import MainPortfolioCard from "@/components/Main/MainPortfolioCard";
import { motion } from "framer-motion";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import MOS from "@/components/common/MOS";
import And from "@/components/common/And";
import React from "react";
import { ContentsData, PortfolioData } from "@/types";
import { fetchContents, fetchMainPage } from "@/firebase/firestore";

const MainPage = () => {
  const { mobileStyle, isMobile } = useMobile();
  const [portfolioDataList, setPortfolioDataList] = React.useState<
    PortfolioData[] | []
  >([]);
  const [contentsDataList, setContentsDataList] = React.useState<
    ContentsData[] | []
  >([]);

  React.useEffect(() => {
    getAllPortfolio();
    getAllContents();
  }, []);

  const getAllPortfolio = async () => {
    const fetchDataList = await fetchMainPage();
    setPortfolioDataList(fetchDataList);
  };

  const getAllContents = async () => {
    const fetchDataList = await fetchContents();
    setContentsDataList(fetchDataList);
  };

  const navigate = useNavigate();

  const { hash } = useLocation();

  const firstOppositRef = useRef<HTMLElement>(null);
  const secondOppositRef = useRef<HTMLElement>(null);
  const textFlowRef = useRef<HTMLDivElement>(null);

  const { isInView: firstIsInView } = useElementInView(firstOppositRef, {
    threshold: 0.5,
  });
  const { isInView: secondIsInView } = useElementInView(secondOppositRef, {
    threshold: 0.5,
    unUseEndViewOver: true,
  });
  const entry = useIntersectionObserver(textFlowRef, {
    threshold: 1,
  });

  const isVisible = !!entry?.isIntersecting;

  const [onceVisible, setOnceVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setOnceVisible(true);
    }
  }, [isVisible]);

  useEffect(() => {
    if (!hash) return;
    const getIdFromDom = document.querySelector(hash);

    getIdFromDom?.scrollIntoView({ behavior: "smooth" });
  }, [hash]);
  return (
    <DefaultTemplate bgWhite>
      <span
        className={classNames({
          "blue-color": firstIsInView || secondIsInView,
        })}
      >
        <MainHeroSection>
          <CommonLayout.Container>
            <CommonLayout.Wrapper style={{ position: "relative" }}>
              <MainHeroBackground />
              <MainHeroTitle>
                <MOS variant="fade-left">We</MOS>{" "}
                <MOS variant="fade-left" delay={0.1}>
                  aim
                </MOS>{" "}
                <MOS variant="fade-left" delay={0.2}>
                  to
                </MOS>{" "}
                <MOS variant="fade-left" delay={0.3}>
                  build
                </MOS>
                <br />
                <MOS variant="fade-left" delay={0.2}>
                  <span className="highlight">
                    <MainHeroTypingText />
                  </span>
                </MOS>
              </MainHeroTitle>
              <MainHeroSubTitle>
                <MOS>
                  100<And>&</And>100 Ventures back mission-aligned founders at
                  the earliest possible stage
                </MOS>
              </MainHeroSubTitle>
            </CommonLayout.Wrapper>
          </CommonLayout.Container>
        </MainHeroSection>
        <MainSection2>
          <CommonLayout.Container>
            <CommonLayout.Wrapper>
              <MainInfoWrapper>
                <MOS>
                  <MainInfoTitle>
                    Invested in 100+ companies with Pride.
                  </MainInfoTitle>
                </MOS>
                <MainInfoText>
                  <MOS delay={0.2}>
                    Empowering these companies to achieve success in the
                    aftermarket.
                  </MOS>
                </MainInfoText>
              </MainInfoWrapper>
              <MainInfoWrapper>
                <MainInfoTitle>
                  <MOS>Continuing our heritage since 2018.</MOS>
                </MainInfoTitle>
                <MainInfoText>
                  <MOS delay={0.2}>
                    Focus on the primary market and incubate early-stage
                    projects.
                  </MOS>
                </MainInfoText>
              </MainInfoWrapper>
              <MainInfoWrapper>
                <MainInfoTitle>
                  <MOS>Performing APAC Crypto Group.</MOS>
                </MainInfoTitle>
                <MainInfoText>
                  <MOS delay={0.2}>
                    A leading player in Seoul. We leverage our track record of
                    closing deals with world-class teams at the forefront of the
                    industry's transformation.
                  </MOS>
                </MainInfoText>
              </MainInfoWrapper>
            </CommonLayout.Wrapper>
          </CommonLayout.Container>
        </MainSection2>
        <span ref={firstOppositRef}>
          <MainSection3>
            <CommonLayout.Container>
              <CommonLayout.Wrapper>
                <MainSectionTitle
                  style={{
                    textAlign: "center",
                    marginBottom: 24,
                    ...mobileStyle({
                      marginBottom: 12,
                    }),
                  }}
                >
                  <MOS>
                    Thesis-driven,
                    <br /> high-conviction funds
                  </MOS>
                </MainSectionTitle>
                <MainSectionSubTitle
                  style={{
                    textAlign: "center",
                    marginBottom: 60,
                    ...mobileStyle({
                      marginBottom: 32,
                    }),
                  }}
                >
                  <MOS>
                    We seek to dramatically accelerate
                    <br /> and amplify each company’s success.
                  </MOS>
                </MainSectionSubTitle>
                <MainSectionText
                  style={{
                    textAlign: "center",
                    maxWidth: 899,
                    margin: "0 auto",
                    marginBottom: 76,
                    ...mobileStyle({
                      marginBottom: 40,
                    }),
                  }}
                >
                  <MOS>
                    Thesis-driven, high-conviction funds that allocate across
                    core themes that we believe will dramatically shape the
                    world.
                  </MOS>
                  <br />
                  <br />
                  <MOS>
                    With our global team of dedicated analysts, specializing in
                    specific sectors of the digital asset industry, we assess
                    projects down to their minute details; from their specific
                    use case and economic models, to their community and
                    cultural impact.
                  </MOS>
                </MainSectionText>
                <MOS style={{ display: "block", margin: "0 auto" }}>
                  <MainSectionButton onClick={() => navigate("/about")}>
                    About Us
                  </MainSectionButton>
                </MOS>
              </CommonLayout.Wrapper>
            </CommonLayout.Container>
            <MainLineMovingWrapper>
              <MainLineMovingMarquee ref={textFlowRef}>
                <motion.div
                  className="motion-container"
                  variants={{
                    animate: {
                      x: ["24%", "0%"],
                      transition: {
                        x: {
                          repeat: 0,
                          duration: !isMobile ? 2.4 : 1.2,
                          ease: "linear",
                        },
                      },
                    },
                  }}
                  initial={"animate"}
                  animate={onceVisible ? "animate" : ""}
                >
                  <motion.div
                    className="motion-wrapper"
                    variants={{
                      animate: {
                        x: !isMobile ? [0, -2659] : [0, -550],
                        transition: {
                          x: {
                            repeat: Infinity,
                            repeatType: "loop",
                            duration: !isMobile ? 12 : 7,
                            ease: "linear",
                            delay: !isMobile ? 2.4 : 1.2,
                          },
                        },
                      },
                    }}
                    animate={onceVisible ? "animate" : ""}
                  >
                    <MainLineMovingText>
                      {Array(4)
                        .fill("")
                        .map((_, index) => (
                          <span className="moving-text-item" key={index}>
                            <b>We are</b>&nbsp;perfection,&nbsp;
                            <b>
                              100<And>&</And>100
                            </b>
                          </span>
                        ))}
                    </MainLineMovingText>
                  </motion.div>
                </motion.div>
              </MainLineMovingMarquee>
            </MainLineMovingWrapper>
          </MainSection3>
        </span>
        <MainSection4>
          <CommonLayout.Container>
            <CommonLayout.Wrapper>
              <MainSectionTitle style={{ marginBottom: 24 }}>
                <MOS>
                  Prior
                  <br /> <span className="highlight">Investment</span>
                </MOS>
              </MainSectionTitle>
              <MainSectionText style={{ maxWidth: 621 }}>
                <MOS>
                  Our predictions are rooted in deep insights, and we're
                  constantly on the lookout for world-changing projects that
                  disrupt the status quo. Got a bold and visionary idea? We're
                  all ears and ready to take risks with seed investments.
                </MOS>
              </MainSectionText>
              <MainInvestmentInfoWrapper>
                {portfolioDataList.map((result, index) => {
                  return (
                    <MainPortfolioCard
                      key={index}
                      src={result.portfolioLogoUrl}
                      oppositeSrc={result.portfolioInvLogoUrl}
                      linkUrl={result.portfolioLink}
                      background={result.logoBackground}
                    />
                  );
                })}
              </MainInvestmentInfoWrapper>
              <MainSectionButton onClick={() => navigate("/portfolio")}>
                View Portfolio
              </MainSectionButton>
            </CommonLayout.Wrapper>
          </CommonLayout.Container>
        </MainSection4>
        <span ref={secondOppositRef}>
          <MainSection5>
            <CommonLayout.Container>
              <CommonLayout.Wrapper>
                <MainSectionTitle style={{ marginBottom: 24 }}>
                  <MOS>
                    News <And>&</And> Insights
                  </MOS>
                </MainSectionTitle>
              </CommonLayout.Wrapper>
            </CommonLayout.Container>
            {!isMobile ? (
              <CommonLayout.Container>
                <CommonLayout.Wrapper>
                  <MainNewsInsightsWrapper>
                    {contentsDataList.map((result, index) => {
                      return (
                        <MainNewsInsightsCard
                          key={index}
                          imageUrl={result.contentsImageUrl}
                          name={result.contentsName}
                          linkUrl={result.contentsLink}
                        />
                      );
                    })}
                  </MainNewsInsightsWrapper>
                </CommonLayout.Wrapper>
              </CommonLayout.Container>
            ) : (
              <MainNewsInsightsWrapper as="div">
                <Swiper
                  style={{ overflow: "visible", padding: "0 16px" }}
                  spaceBetween={20}
                  slidesPerView={1.4}
                >
                  {contentsDataList.map((result, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <MainNewsInsightsCard
                          key={index}
                          imageUrl={result.contentsImageUrl}
                          name={result.contentsName}
                          linkUrl={result.contentsLink}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </MainNewsInsightsWrapper>
            )}
            <MainSectionButton onClick={() => navigate("/insights")}>
              View all
            </MainSectionButton>
          </MainSection5>
          <MainSection6 id="team">
            <CommonLayout.Container>
              <CommonLayout.Wrapper>
                <MainSectionTitle
                  style={{
                    marginBottom: 56,
                    ...mobileStyle({
                      marginBottom: 32,
                    }),
                  }}
                >
                  <MOS>
                    We aim to build
                    <br />
                    the vision of the future.
                  </MOS>
                </MainSectionTitle>
                <MainSectionText
                  style={{
                    maxWidth: 530,
                    marginLeft: "auto",
                    ...mobileStyle({
                      maxWidth: 199,
                    }),
                  }}
                >
                  <MOS>
                    Committed to advancing the adoption of blockchain and
                    cryptocurrency, we provide funding, guidance, and industry
                    insights to help startups reach their full potential in the
                    blockchain ecosystem.
                  </MOS>
                </MainSectionText>
                <MainTeamWrapper>
                  <MainTeamRow>
                    <MainTeamItem>
                      <MOS>
                        <MainTeamName>Victor Jung</MainTeamName>
                        <MainTeamPart>CEO / Founder</MainTeamPart>
                        <MainTeamInfoList>
                          <MainTeamInfoItem>
                            Former Software Engineer at Samsung Electronics
                          </MainTeamInfoItem>
                          <MainTeamInfoItem>
                            Strategic Advisor / Project Incubator
                          </MainTeamInfoItem>
                        </MainTeamInfoList>
                      </MOS>
                    </MainTeamItem>
                    <MainTeamItem>
                      <MOS>
                        <MainTeamName>JJ Min</MainTeamName>
                        <MainTeamPart>
                          Chief Operating Officer / Co-Founder
                        </MainTeamPart>
                        <MainTeamInfoList>
                          <MainTeamInfoItem>
                            Former Healthcare/eCommerce Startup Founder
                          </MainTeamInfoItem>
                          <MainTeamInfoItem>
                            Former Samsung Electronics
                            <br />
                            <MainTeamSubInfo>
                              - Fintech Business Development
                              <br />
                              - Leadership in building New Technologies
                              <br />- SW engineer
                            </MainTeamSubInfo>
                          </MainTeamInfoItem>
                        </MainTeamInfoList>
                      </MOS>
                    </MainTeamItem>
                  </MainTeamRow>
                  <MainTeamRow>
                    <MainTeamItem style={{ maxWidth: 592 }}>
                      <MOS>
                        <MainTeamName>Jayden Park</MainTeamName>
                        <MainTeamPart>
                          Chief Investment Officer / Co-Founder
                        </MainTeamPart>
                        <MainTeamInfoList>
                          <MainTeamInfoItem>
                            Former Secretary of National Assembly members
                          </MainTeamInfoItem>
                          <MainTeamInfoItem>
                            Guinness World Record Holder
                          </MainTeamInfoItem>
                        </MainTeamInfoList>
                      </MOS>
                    </MainTeamItem>
                    <MainTeamItem>
                      <MOS>
                        <MainTeamName>Jay Kim</MainTeamName>
                        <MainTeamPart>Manager</MainTeamPart>
                        <MainTeamInfoList>
                          <MainTeamInfoItem>
                            Experienced Business Development Manager{" "}
                            <And>&</And> Market Strategist
                          </MainTeamInfoItem>
                          <MainTeamInfoItem>
                            Discover Business/Tech opportunities <And>&</And>{" "}
                            execute strategic Investments <And>&</And>{" "}
                            Collaborations
                          </MainTeamInfoItem>
                        </MainTeamInfoList>
                      </MOS>
                    </MainTeamItem>
                  </MainTeamRow>
                  <MainTeamRow>
                    <MainTeamItem>
                      <MOS>
                        <MainTeamName>Danny Um</MainTeamName>
                        <MainTeamPart>Manager</MainTeamPart>
                        <MainTeamInfoList>
                        <MainTeamInfoItem>Web2 <And>&</And>{" "} Web3 Engineer</MainTeamInfoItem>
                          <MainTeamInfoItem>
                            Experience in the next domains
                            <MainTeamSubInfo>
                             - Blockchain 
                            <br />
                             - Cloud infrastructure
                            </MainTeamSubInfo>
                          </MainTeamInfoItem>
                        </MainTeamInfoList>
                      </MOS>
                    </MainTeamItem>
                  </MainTeamRow>
                </MainTeamWrapper>
              </CommonLayout.Wrapper>
            </CommonLayout.Container>
          </MainSection6>
        </span>
      </span>
    </DefaultTemplate>
  );
};

export default MainPage;

const MainHeroTypingText = () => {
  const { word } = useTypingText({
    words: [
      "the Vision of Crypto",
      "the Growth of Innovation",
      "the Future of Digital Life",
    ],
    keySpeed: 100,
    backspaceKeySpeed: 72,
    maxPauseAmount: 13,
  });

  return <>{word}</>;
};

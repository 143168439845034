import { Routes, Route, useLocation } from "react-router-dom";
import GlobalStyles from "@/styles/GlobalStyles";
import FontStyles from "./styles/Font";
import MainPage from "@/pages/main";
import PortfolioPage from "@/pages/portfolio";
import NodePage from "@/pages/node";
import AboutPage from "@/pages/about";
import InsightsPage from "@/pages/insights";
import { useLayoutEffect } from "react";

function App() {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <GlobalStyles />
      <FontStyles />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/node" element={<NodePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/insights" element={<InsightsPage />} />
      </Routes>
    </>
  );
}

export default App;

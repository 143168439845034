import React from "react";
import { useTheme } from "styled-components";
import useMediaQuery from "./useMediaQuery";

const useMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(
    `(max-width:${theme.breakpoints.points.mobile}px)`
  );

  const mobileStyle = (styles: React.CSSProperties): React.CSSProperties => {
    if (!isMobile) return {};
    return styles;
  };

  return {
    isMobile,
    mobileStyle,
  };
};

export default useMobile;

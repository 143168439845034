import { useState } from "react";
import {
  CardButton,
  CardContent,
  CardContentWrapper,
  CardImageLogo,
  CardMainWrapper,
  CardSubTitle,
  CardTitle,
  CardWrapper,
  MobileCardFullContentIconButton,
} from "./NodeCard.styles";
import Icon from "@/components/common/Icon";
import classNames from "classnames";
import useMobile from "@/hooks/useMobile";

type NodeCardProps = {
  logoSrc: string;
  title: string;
  subTitle: string;
  content: string;
  url: string;
};
const NodeCard = ({
  logoSrc,
  title,
  subTitle,
  content,
  url,
}: NodeCardProps) => {
  const [mobileFullContent, setMobileFullContent] = useState(false);
  const { isMobile } = useMobile();
  return (
    <CardWrapper>
      <CardMainWrapper>
        <CardImageLogo src={logoSrc} />
        <CardTitle>{title}</CardTitle>
        <CardSubTitle>{subTitle}</CardSubTitle>
        <CardButton>
          <a href={url} target="_blank" rel="noreferrer">
            VIEW {isMobile && "Portfolio"}
          </a>
        </CardButton>
      </CardMainWrapper>
      <CardContentWrapper
        className={classNames({
          isFullContent: mobileFullContent,
        })}
      >
        <CardContent>{content}</CardContent>
      </CardContentWrapper>
      <MobileCardFullContentIconButton
        className={classNames({
          isFullContent: mobileFullContent,
        })}
        onClick={() => setMobileFullContent((prev) => !prev)}
      >
        <Icon icon="ChevronDown" />
      </MobileCardFullContentIconButton>
    </CardWrapper>
  );
};

export default NodeCard;

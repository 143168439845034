import {
  PortfolioWrapper,
  PortfolioHeadWrapper,
  PortfolioCardListWrapper,
} from "@/components/Portfolio/Portfolio.styles";
import PortfolioCard from "@/components/Portfolio/PortfolioCard";
import And from "@/components/common/And";
import CommonLayout from "@/components/common/CommonLayout";
import MOS from "@/components/common/MOS";
import SubPageTitle from "@/components/common/SubPageTitle";
import Typography from "@/components/common/Typography";
import { fetchPortfolio } from "@/firebase/firestore";
import useMobile from "@/hooks/useMobile";
import DefaultTemplate from "@/templates/DefaultTemplate";
import { PortfolioData } from "@/types";
import React from "react";

const PortfolioPage = () => {
  const { mobileStyle } = useMobile();
  const [portfolioDataList, setPortfolioDataList] = React.useState<
    PortfolioData[] | []
  >([]);

  React.useEffect(() => {
    getAllPortfolio();
  }, []);

  const getAllPortfolio = async () => {
    const fetchDataList = await fetchPortfolio();
    setPortfolioDataList(fetchDataList);
  };

  return (
    <DefaultTemplate>
      <PortfolioWrapper>
        <CommonLayout.Container>
          <CommonLayout.Wrapper>
            <PortfolioHeadWrapper>
              <SubPageTitle
                style={{
                  marginBottom: 60,
                  ...mobileStyle({ marginBottom: 20 }),
                }}
              >
                <MOS variant="fade-left">Investing</MOS>{" "}
                <span className="highlight">
                  <MOS variant="fade-left" delay={0.2}>
                    iconic
                  </MOS>
                  <br />
                  <MOS variant="fade-left" delay={0.3}>
                    crypto
                  </MOS>{" "}
                  <MOS variant="fade-left" delay={0.4}>
                    founders
                  </MOS>
                </span>
              </SubPageTitle>
              <Typography
                family="Pretendard"
                variant="body1"
                style={{
                  maxWidth: 668,
                  marginLeft: "auto",
                  ...mobileStyle({ fontSize: 14, maxWidth: 260 }),
                }}
              >
                <MOS>
                  100<And>&</And>100 Ventures focus on where we can have the
                  biggest impact, allowing us to offer unparalleled, tailor-made
                  advice and engagement. Across consumer and enterprise, we
                  support transformational companies to make the step change
                  into growth, from our initial investment all the way through.
                </MOS>
              </Typography>
            </PortfolioHeadWrapper>

            <PortfolioCardListWrapper>
              {portfolioDataList.map((result, index) => {
                return (
                  <PortfolioCard
                    key={index}
                    logoUrl={result.portfolioLogoUrl}
                    invLogoUrl={result.portfolioInvLogoUrl}
                    logoBackground={result.logoBackground}
                    companyName={result.portfolioCompanyName}
                    category={result.portfolioCategory}
                    linkUrl={result.portfolioLink}
                  />
                );
              })}
            </PortfolioCardListWrapper>
          </CommonLayout.Wrapper>
        </CommonLayout.Container>
      </PortfolioWrapper>
    </DefaultTemplate>
  );
};

export default PortfolioPage;

import styled, { css } from "styled-components";
import Typography from "../common/Typography";
import Icon from "../common/Icon";
import Button from "../common/Button";

export const oppositColorDuration = "1.05s";
export const oppositColorTransition = "all 1.05s ease";

const blueColorCss = css`
  ${({ theme }) => css`
    transition: ${oppositColorTransition};
    color: ${theme.color.gray[900]};
    .blue-color & {
      color: ${theme.color.bg[50]};
    }
  `}
`;

const blueBackgroundCss = css`
  ${({ theme }) => css`
    transition: ${oppositColorTransition};
    background: ${theme.color.bg[50]};
    .blue-color & {
      background: ${theme.color.point};
    }
  `}
`;

const blueButtonCss = css`
  ${({ theme }) => css`
    transition: ${oppositColorTransition};
    .blue-color & {
      color: ${theme.color.point};
      background-color: ${theme.color.bg[50]};
    }
  `}
`;

export const MainHeroSection = styled.section`
  position: relative;
  height: 606px;
  padding-top: 100px;

  ${blueBackgroundCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      padding-top: 30px;
      height: 246px;
    }
  `}
`;
export const MainHeroTitle = styled(Typography).attrs({
  family: "SanomatWeb",
  variant: "h2",
})`
  ${blueColorCss};

  ${({ theme }) => css`
    position: relative;
    border-bottom: 1px solid ${theme.color.point};
    padding-bottom: 60px;
    margin-bottom: 32px;

    & .highlight {
      color: ${theme.color.point};
    }
    .blue-color & {
      border-bottom: 1px solid ${theme.color.bg[50]};
      &.highlight {
        color: ${theme.color.bg[50]};
      }
    }

    ${theme.breakpoints.media.mobile} {
      font-size: 27px;
      padding-bottom: 16px;
      margin-bottom: 12px;
    }
  `}
`;
export const MainHeroSubTitle = styled(Typography).attrs({
  family: "Pretendard",
  variant: "subtitle1",
})`
  position: relative;
  font-weight: 400;
  text-align: right;
  margin-left: auto;
  max-width: 543px;

  ${blueColorCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 16px;
      max-width: 290px;
    }
  `}
`;
export const MainHeroBackground = styled(Icon).attrs({
  icon: "Ampersand",
})`
  position: absolute;
  left: 0;
  top: 40px;
  width: 564px;
  height: 549px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      top: 13px;
      width: 220px;
      height: 214px;
    }
  `}
`;

export const MainSection2 = styled.section`
  padding: 170px 0 160px;
  ${blueBackgroundCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      padding: 40px 0 72px;
    }
  `}
`;

export const MainInfoWrapper = styled.div`
  max-width: 993px;
  &:not(:last-child) {
    margin-bottom: 72px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      margin-bottom: 44px;
    }
  `}
`;
export const MainInfoTitle = styled.h4`
  font-family: SanomatWeb;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 4px;
  ${blueColorCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 21px;
    }
  `}
`;
export const MainInfoText = styled(Typography).attrs({
  family: "Pretendard",
  variant: "body1",
})`
  ${blueColorCss};
  min-height: 37px;
  ${({ theme }) => css`
    color: ${theme.color.point};
    ${theme.breakpoints.media.mobile} {
      min-height: 22px;
      font-size: 16px;
    }
  `}
`;

export const MainSectionTitle = styled(Typography).attrs({
  family: "SanomatWeb",
  variant: "subtitle1",
})`
  ${blueColorCss};
  ${({ theme }) => css`
    & .highlight {
      color: ${theme.color.point};
    }

    .blue-color & {
      & .highlight {
        color: ${theme.color.bg[50]};
      }
    }
    ${theme.breakpoints.media.mobile} {
      font-size: 27px;
    }
  `}
`;
export const MainSectionSubTitle = styled(Typography).attrs({
  family: "Pretendard",
  variant: "subtitle1",
})`
  ${blueColorCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 18px;
    }
  `}
`;

export const MainSectionText = styled(Typography).attrs({
  family: "Pretendard",
  variant: "body1",
})`
  ${blueColorCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 16px;
    }
  `}
`;

export const MainSectionButton = styled(Button).attrs({
  size: "large",
  variant: "fillPoint",
})`
  margin: 0 auto;
  display: block;
  ${blueButtonCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      height: 42px;
      font-size: 16px;
      padding: 0 36px;
    }
  `}
`;

export const MainSection3 = styled.section`
  padding: 160px 0 80px;
  ${blueBackgroundCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      padding: 72px 0 30px;
    }
  `}
`;

export const MainLineMovingWrapper = styled.div`
  position: relative;
  height: 280px;
  margin-top: 236px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      margin-top: 102px;
    }
    @media screen and (max-width: 768px) {
      height: 56px;
    }
  `}
`;

export const MainLineMovingMarquee = styled.div`
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 280px;
  overflow-x: hidden;

  @media screen and (max-width: 768px) {
    height: 56px;
  }
`;

export const MainLineMovingText = styled.h4`
  font-family: SanomatWeb;
  font-size: 200px;
  font-weight: 400;
  white-space: nowrap;

  transition: all 0.8s ease;

  ${({ theme }) => css`
    color: ${theme.color.gray[900]};

    .blue-color & {
      color: ${theme.color.bg[50]};
    }
  `}

  & b {
    font-weight: 700;
  }

  & .moving-text-item {
    display: inline-block;
    margin-right: 160px;
  }

  @media screen and (max-width: 768px) {
    font-size: 40px;

    & .moving-text-item {
      display: inline-block;
      margin-right: 50px;
    }
  }
`;

export const MainSection4 = styled.section`
  padding: 200px 0 160px;
  ${blueBackgroundCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      padding: 72px 0;
    }
  `}
`;

export const MainInvestmentInfoWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 60px;
  margin-top: 80px;
  margin-bottom: 76px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      grid-template-columns: repeat(2, 1fr);
      gap: 0;
      margin-top: 36px;
      margin-bottom: 36px;
    }
  `}
`;

export const MainSection5 = styled.section`
  padding: 160px 0 158px;
  ${blueBackgroundCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      padding: 72px 0;
    }
  `}
`;

export const MainNewsInsightsWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 50px 0;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      margin: 20px 0 59px;
      display: block;
      overflow: hidden;
    }
  `}
`;

export const MainSection6 = styled.section`
  padding: 160px 0;
  ${blueBackgroundCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      padding: 72px 0;
    }
  `}
`;

export const MainTeamWrapper = styled.div`
  margin-top: 160px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      margin-top: 68px;
    }
  `}
`;

export const MainTeamRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;

  &:not(:last-child) {
    margin-bottom: 100px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      flex-direction: column;
      gap: 59px;

      &:not(:last-child) {
        margin-bottom: 59px;
      }
    }
  `}
`;

export const MainTeamItem = styled.div`
  max-width: 530px;
  width: 100%;

  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      max-width: unset;
    }
  `}
`;

export const MainTeamName = styled(Typography).attrs({
  family: "Pretendard",
  variant: "subtitle2",
})`
  margin-bottom: 4px;
  ${blueColorCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 18px;
    }
  `}
`;

export const MainTeamPart = styled(Typography).attrs({
  family: "Pretendard",
  variant: "body2",
})`
  margin-bottom: 20px;
  ${blueColorCss};
  ${({ theme }) => css`
    ${theme.breakpoints.media.mobile} {
      font-size: 16px;
      margin-bottom: 16px;
    }
  `}
`;

export const MainTeamInfoList = styled.ul``;
export const MainTeamInfoItem = styled(Typography).attrs({
  as: "li",
  family: "Pretendard",
  variant: "body4",
})`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  ${({ theme }) => css`
    position: relative;
    padding-left: 32px;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 12px;
      top: 12px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: ${theme.color.gray[900]};
    }

    ${theme.breakpoints.media.mobile} {
      font-size: 14px;
      padding-left: 22px;

      &:before {
        content: "";
        width: 3px;
        height: 3px;
        top: 8px;
        left: 8px;
      }
    }
    ${blueColorCss};
    .blue-color & {
      &:before {
        background-color: ${theme.color.bg[50]};
      }
    }
  `}
`;

export const MainTeamSubInfo = styled(Typography).attrs({
  family: "Pretendard",
  variant: "caption1",
})`
  margin-top: 8px;
  ${({ theme }) => css`
    color: ${theme.color.gray[500]};

    ${theme.breakpoints.media.mobile} {
      font-size: 14px;
    }
  `}
`;

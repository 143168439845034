import Typography from "@/components/common/Typography";
import styled, { css } from "styled-components";
import { oppositColorDuration, oppositColorTransition } from "../index.styles";

export const CardWrapper = styled.li``;

export const CardImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 74%;
  margin-bottom: 16px;

  ${({ theme }) => css`
    .blue-color & .image-cover {
      transition: color ${oppositColorDuration} ease;
      background: ${theme.color.bg[50]};
    }
  `}
`;
export const CardImage = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CardText = styled(Typography).attrs({
  family: "Pretendard",
  variant: "caption1",
})`
  ${({ theme }) => css`
    transition: ${oppositColorTransition};
    color: ${theme.color.gray[900]};
    .blue-color & {
      color: ${theme.color.bg[50]};
    }
    overflow: "hidden";
    text-overflow: "ellipsis";
  `}
`;

import { RefObject, useCallback, useEffect, useRef, useState } from "react";

type useElementInViewProps = {
  threshold?: number;
  unUseEndViewOver?: boolean;
};
const useElementInView = (
  elementRef: RefObject<Element>,
  { threshold = 1, unUseEndViewOver = false }: useElementInViewProps
) => {
  const winHeightRef = useRef(window.innerHeight);
  const [isInView, setIsInView] = useState(false);

  const handleScroll = () => {
    const halfWinHeight = winHeightRef.current * threshold;
    const sclY = window.scrollY;
    const node = elementRef?.current as HTMLElement;
    if (!node) return;
    const offsetTop = node?.offsetTop || 0;
    const nodeRect = node.getBoundingClientRect();
    const offsetBottom = offsetTop + nodeRect.height;

    const startIsInView = sclY > offsetTop - halfWinHeight;
    const endIsInView = sclY > offsetBottom - halfWinHeight;

    if (startIsInView && !endIsInView) {
      setIsInView(true);
      return;
    }

    if (endIsInView && !unUseEndViewOver) {
      setIsInView(false);
      return;
    }

    if (!startIsInView || !unUseEndViewOver) {
      setIsInView(false);
    }
  };

  const handleResize = useCallback(() => {
    winHeightRef.current = window.innerHeight;
  }, []);

  useEffect(() => {
    const node = elementRef?.current;

    if (!node) return;
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [elementRef?.current, threshold, unUseEndViewOver]);

  return {
    isInView,
  };
};

export default useElementInView;
